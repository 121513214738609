
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import "viewerjs/dist/viewer.css";

export default defineComponent({
  name: "underwriter-motor-insurance-policy-information",
  components: { GoBackButton },
  props: ["publicId"],
  data() {
    return {
      policies: [],
      imageURL: "",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Motor Insurance", [
        "Client",
        "Polcies",
        "Policy Information",
      ]);
    });
  },
  created() {
    const router = useRouter();

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Policy images route
    this.imageURL = variables.POLICY_IMAGES_ROUTE;

    // Get the details of the vehicle in schdule list
    this.getVehicleDetails();

    //Set page title
    document.title =
      "Underwriter: Motor Insurance Policy Details | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    getVehicleDetails() {
      const router = useRouter();
      //Get cart item details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.UNDERWRITERS_POLICY_DETAILS_ROUTE}/${this.publicId}/details`
        )
          .then(({ data }) => {
            //Assign data to corresponding fields
            this.policies = data.data.items;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/no-photo-available.png";
    },
  },
});
